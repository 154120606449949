import Post from '@components/post/post';
import SectionTag from '@components/section-tag/section-tag';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import Carret from '@img/caret-right-solid.svg';
import Button from '@components/button/button';
import BorderButton from '@components/border-button/border-button';
import CategoryButtons from '@domain/category-buttons/category-buttons';

interface GuidesProps {
  posts: any[];
  title?: string;
  className?: string;
}
export default function Guides({
  posts,
  title = 'Poradniki ubezpieczeniowe',
  className = '',
}: GuidesProps) {
  return (
    <div
      className={`relative w-full overflow-hidden bg-main py-6 pt-10 lg:py-14 ${className}`}
    >
      <div className="container relative z-10 mx-auto px-4">
        <div className="">
          <SectionTag tag="span">STREFA WIEDZY</SectionTag>
          <h2 className="mt-6 text-left text-[32px] font-black leading-snug text-text lg:text-left lg:text-[42px] lg:leading-tight">
            {title}
          </h2>
          <p className="mt-6 max-w-xl text-sm lg:text-lg">
            Skorzystaj z wiedzy naszych specjalistów. Dowiedz się więcej
            o&nbsp;ubezpieczeniach dla dzieci i wybieraj świadomie.
          </p>
        </div>
        <CategoryButtons activeCategory="wszystko" />
        <div className="mt-10">
          <div className="grid auto-rows-max grid-cols-1 gap-4 lg:gap-7 xl:grid-cols-3">
            {posts.map((post: any) => {
              const image = getImage(post.node.featuredImage.node.localFile)!;
              return <Post key={post.node.id} post={post.node} image={image} />;
            })}
          </div>
        </div>
        <div className="mt-4 flex w-full justify-end">
          <Button
            visualType="tertiary"
            to="/poradniki/"
            className="ml-2 !text-sm normal-case"
          >
            Zobacz wszystkie artykuły
          </Button>
        </div>
      </div>
    </div>
  );
}
