import React from 'react';
import { default as Sticky } from 'react-sticky-box';

interface IStickyBoxProps {
  children: React.ReactNode;
}

export default function StickyBox({ children }: IStickyBoxProps) {
  return (
    <Sticky offsetTop={120} offsetBottom={0}>
      {children}
    </Sticky>
  );
}
