import React from 'react';
import CalcSvg from '../../assets/um-calculator.svg';
import config from '../../../config.json';
import Underscore from '@img/starry-underscore.svg';
import Button from '@components/button/button';
type Props = {};

const CompareBox = (props: Props) => {
  return (
    <div className="rounded-2xl border-t-8 border-t-primary bg-white">
      <div className="px-5 py-6 pb-5 lg:py-10 lg:pb-7">
        <p className=" flex flex-col text-center text-4xl font-black">
          Zagwarantuj swojemu dziecku na dobry start
          <span className="text-6xl">nawet</span>
          <span className="relative mx-auto mt-2 w-fit text-6xl text-secondary">
            40 000 zł
            <img
              src={Underscore}
              alt=""
              className="absolute -bottom-8 left-0 scale-125"
            />
          </span>
        </p>
        <a
          href={config.CALC_URL}
          target="_blank"
          className="mt-16 flex justify-center"
        >
          <Button visualType="primary">UZYSKAJ WYCENĘ UBEZPIECZENIA</Button>
        </a>
      </div>
    </div>
  );
};

export default CompareBox;
