import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import config from '../../config.json';
import Layout from '@components/layout/layout';
import StickyBox from '@components/sticky-box/sticky-box';
import '../styles/blog-page.sass';
import { graphql, Link } from 'gatsby';
import CalendarSvg from '@img/calendar.svg';
import ClockSvg from '@img/clock.svg';
import Seo from '@components/seo/seo';
import ArrowSvg from '@img/um-arrow-pagination.svg';
import CompareBox from '../domain/compare-box/compare-box';
import Avatar from '@img/avatar.png';
import BgPost from '@img/bg-post.svg';
import Guides from '@domain/guides/guides';

type Props = {
  pageContext: any;
  data: any;
  readingTime: any;
};

const Post = ({ pageContext, data }: Props) => {
  const post = pageContext.data;
  const readingTime = pageContext.readingTime;

  const pageQueryData = data;
  const image = getImage(
    pageQueryData.wpPost.featuredImage.node.localFile
  ) as IGatsbyImageData;
  const imageMeta = image?.images?.fallback?.src || undefined;
  const type = pageContext.type;

  const next = pageContext.next;
  const previous = pageContext.previous;

  const posts = data.allWpPost.edges;

  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: post.seo.title,
    description: post.seo.metaDesc,
    image: `${config.SITE_URL}/${imageMeta}`,
    datePublished: new Date(post.dateGmt),
    dateModified: new Date(post.modifiedGmt),
    author: {
      '@type': 'Person',
      name: post.author.node.name,
    },
    publisher: {
      '@type': 'Organization',
      name: 'LeadGuru Sp. z o.o.',
    },
  };

  const nnWidgetArticles = [
    'ubezpieczenie-nnw-dla-dziecka'
  ];

  return (
    <Layout>
      <Seo
        title={post.seo.title}
        description={post.seo.metaDesc}
        keywords={post.seo.focuskw}
        article={true}
        pathname={`/${type}/${post.slug}/`}
        image={imageMeta}
        jsonLd={JSON.stringify(articleStructuredData)}
      />
      <div className="relative ">
        <div
          className="absolute top-0 left-0 right-0 h-[420px] w-full"
          style={{
            background: `url(${BgPost})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
            zIndex: '-1'
          }}
        ></div>
        <div className="container mx-auto px-4">
          <h1 className="relative py-16 text-center text-2xl font-bold text-white lg:px-10 lg:text-[42px] lg:leading-[50px]">
            {post.title}
          </h1>
        </div>
        <div className="container mx-auto px-4 py-4 lg:py-8">
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-6">
            <div className="lg:col-span-4">
              <div className="w-full">
                <GatsbyImage
                  image={image}
                  alt=""
                  loading="eager"
                  className="rounded-3xl"
                />
              </div>
              <div className="my-4 flex flex-col lg:my-4 lg:flex-row lg:justify-end">
                <div className="mt-6 flex lg:mt-0">
                  <div className="flex items-center lg:mt-0">
                    <img src={ClockSvg} alt="" />
                    <span className="font-secondary pl-2">
                      {Math.floor(readingTime.minutes)} min.
                    </span>
                  </div>
                  <div className="flex items-center pl-4 lg:mt-0">
                    <img src={CalendarSvg} alt="" className="h-4 w-4" />
                    <time className="font-secondary pl-2">{post.dateGmt}</time>
                  </div>
                </div>
              </div>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              ></div>

              <div className="mt-8 flex lg:mt-10 lg:items-start">
                <img
                  src={post?.author?.node?.avatar?.url || Avatar}
                  title=""
                  className="h-20 w-20 rounded-full"
                  alt=""
                />
                <div className="p-4">
                  <div className="flex flex-col">
                    <p className="font-secondary pb-0 text-lg">
                      {post.author.node.name}
                    </p>
                    <p className="font-secondary text-[#898E98]">
                      {post.author.node.seo.title}
                    </p>
                  </div>
                  <p className="font-secondary mt-4">
                    {post.author.node.seo.metaDesc}
                  </p>
                </div>
              </div>
              <div className="mt-6 flex w-full justify-between">
                <div className="">
                  {previous?.slug && (
                    <Link
                      to={`/poradniki/${previous.slug}/`}
                      className="flex items-center hover:text-secondary"
                    >
                      <img src={ArrowSvg} className="rotate-180" alt="" />
                      <span className="pl-2">Poprzedni artykuł</span>
                    </Link>
                  )}
                </div>
                <div className="">
                  {next?.slug && (
                    <Link
                      to={`/poradniki/${next.slug}/`}
                      className="group flex items-center self-end hover:text-secondary"
                    >
                      <span className="pr-2">Następny artykuł</span>
                      <img
                        src={ArrowSvg}
                        alt=""
                        className="group-hover:stroke-secondary"
                      />
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:col-span-2">
              {nnWidgetArticles.includes(post.slug) && (
                <div class="nn-contact-form">
                  <iframe src="https://lp.nn.pl/leadguru" width="100%" height="660" scrolling="no"></iframe>
                </div>
              )}
              <StickyBox>
                <CompareBox />
              </StickyBox>
            </div>
          </div>
        </div>
        <Guides
          posts={posts}
          title="Dowiedz się więcej!"
          className="!bg-gradient-to-r !from-[#FBF5FE] !to-white"
        />
      </div>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query BlogPost($id: String) {
    wpPost(id: { eq: $id }) {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, height: 360, quality: 90)
            }
          }
        }
      }
    }
    allWpPost(
      sort: { order: DESC, fields: dateGmt }
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: {
                in: ["pozostale", "bezpieczenstwo", "edukacja", "oszczedzanie"]
              }
            }
          }
        }
      }
      limit: 6
    ) {
      edges {
        node {
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750, height: 400, quality: 90)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          zajawkaBlog {
            zajawka
          }
          content
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
  }
`;
